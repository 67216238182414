import { RealEstateResultListUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { SellerProfileContainer } from '@bbx/search-journey/sub-domains/search/components/common/lead-journey/SellerProfile/SellerProfileContainer'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { RealEstateResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/RealEstateResultListContainer'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { NextPage } from 'next'
import { sellerProfileGetInitialProps, SellerProfileProps } from 'pages/iad/kaufen-und-verkaufen/verkaeuferprofil/[...seopath]'
import React, { Fragment } from 'react'

export const SellerProfilePage: NextPage<SellerProfileProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <RealEstateResultListUserZoom />
                <DesktopBreadcrumbs breadcrumbs={breadcrumbs} />
                <SellerProfileContainer
                    sellerProfileLoginId={props.sellerProfileLoginId}
                    verticalId={props.verticalId}
                    sellerProfile={props.sellerProfileWrapper.sellerProfile}
                    initialFollowerProfile={props.sellerProfileWrapper.sellerFollowerDTO}
                    contextLinkList={props.sellerProfileWrapper.contextLinkList.contextLink}
                    taggingData={props.searchResult.taggingData}
                    trustSignals={props.trustSignals}
                    searchAgentOptionsLink={findContextLinkWithIdFromArray(
                        'searchAgentOptionsLink',
                        props.searchResult.searchContextLinks.contextLink,
                    )}
                />
                <RealEstateResultListContainer searchResult={props.searchResult} pageType="seller-profile" pageViewEvent="seller_profile" />
            </Fragment>
        )
    }
}

const breadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Immobilien', seoUrl: staticRelativeCanonicals.EstateHome },
    { displayName: 'Verkäuferprofil', seoUrl: '' },
]

SellerProfilePage.getInitialProps = sellerProfileGetInitialProps(verticalIdMap.ESTATE, '/immobilien/verkaeuferprofil')
SellerProfilePage.Layout = SkyscraperLayout

export default SellerProfilePage
